<template>
	<v-dialog :value='value' :showCloseCross='false' :width='884' class='a-transfers-dialog' class-content='border-radius' @close-dialog="closeDialog">
		<div class='a-card-details-header'>
			<div class="row align-items-center">
				<div class="col">
					<p class='a-card-details-header-title'>{{$t("account.offers.transfers.transfer_order")}}</p>
				</div>
				<div class="col-auto">
					<div class='a-card-details-header-button' @click.stop='closeDialog'>
						<v-icon icon='cross' size='17' />
					</div>
				</div>
			</div>
		</div>
		<ValidationObserver v-slot="{ invalid }">
			<div class='a-transfers-dialog-content'>
				<ul class='a-transfers-dialog-list'>
					<li class='a-transfers-dialog-list-item'>{{$t("account.offers.transfers.descr_1")}}</li>
					<li class='a-transfers-dialog-list-item'>{{$t("account.offers.transfers.descr_2")}}</li>
				</ul>
				<form class='a-transfers-dialog-form'>
					<div class='a-transfers-dialog-form-item' v-for="(item, i) in items" :key='i'>
						<p class='a-transfers-dialog-form-item-title'>{{item.title}}</p>
						<div class="row">
							<div class="col-6" v-for="(element, index) in item.form" :key='index'>
								<ValidationProvider
									:name="element.label"
									:rules="element.rules ? element.rules : { required: false }"
									v-slot='{errors, classes}'
								>
									<v-text-field
										:label="element.label"
										:placeholder="element.placeholder"
										:class='classes'
										:name='item.key + "-" + element.name'
										type='text'
										v-model='form[item.key][element.name]'
										:errorMessage='errors[0] && element.name === "time" ? $t("account.form.message.errors.time_must_be_format") : errors[0]'
										:mask='element.mask ? element.mask : false'
									/>
								</ValidationProvider>
							</div>
						</div>
					</div>
                    <p class='a-transfers-dialog-form-item-title'>{{$t("account.offers.transfers.contact")}}</p>
                    <div class="row">
                        <div class="col-6" v-for="(element, index) in contactFields" :key="index">
                            <ValidationProvider
                                :name="element.label"
                                :rules="element.rules ? element.rules : { required: false }"
                                v-slot='{errors, classes}'
                            >
                                <v-text-field
                                    :label="element.label"
                                    :placeholder="element.placeholder"
                                    :class='classes'
                                    :name='element.name'
                                    type='text'
                                    v-model='form[element.name]'
                                    :errorMessage='errors[0]'
                                    :mask='element.mask ? element.mask : false'
                                />
                            </ValidationProvider>
                        </div>
                    </div>
				</form>
			</div>
			<div class='a-card-details-footer'>
				<div class='a-card-details-footer__divider'>
					<div class='divider' />
				</div>
				<div class="row align-items-center">
					<div class="col">
						<p class='a-card-details-footer-title' v-if='total'>{{total}}</p>
						<p class='a-card-details-footer-descr' v-if='nights'>{{$t('account.the_price_is_generally_for')}} {{nights}} {{$helpers.declOfNum(nights, $t('account.declOfNum.nights'))}}</p>
					</div>
					<div class="col-auto">
						<v-button xxsmall color='green white--text' class='a-card-details-footer-button'
							:loading='loading_btn'
							:disabled="invalid"
							@click='active_transfer && active_transfer.id ? requestOrderHotel() : transferDetails()'
						>{{$t("account.buttons.continue")}}</v-button>
					</div>
				</div>
			</div>
		</ValidationObserver>
	</v-dialog>
</template>

<script>
	import { transferDetails, addOrder } from '@/services/request';
	import { i18n } from '@/plugins/i18n';

	export default {
		data: () => ({
			form: {
				in: {
					number: '',
					time: ''
				},
				out: {
					number: '',
					time: ''
				},
                email: null,
                phone: null,
			},
			loading_btn: false
		}),
		props: ['value', 'details', 'total', 'nights', 'offer', 'active_transfer', 'userComment'],
		computed: {
            contactFields(){
                return [
                    {label: i18n.t("auth.sign_in.email"), placeholder: 'Email', name: 'email', rules: 'email'},
                    {label: i18n.t("auth.sign_up.phone_number"), placeholder: '+380 50 000 54 54', name: 'phone', rules: 'required|numeric|length:12', mask: '+380 ## ### ## ##'}
                ]
            },
            items() {
				return [
					{title: i18n.t("account.offers.transfers.transfer_on_arrival"), key: "in", form: [
						{label: i18n.t("account.offers.transfers.flight_number"), placeholder: 'ВА27560', name: 'number', rules: 'required'},
						{label: i18n.t("account.offers.transfers.flight_arrival_time"), placeholder: '00:00', name: 'time', rules: 'required|length:5', mask: '##:##'}
					]},
					{title: i18n.t("account.offers.transfers.transfer_on_departure"), key: "out", form: [
						{label: i18n.t("account.offers.transfers.flight_number"), placeholder: 'ВА27560', name: 'number', rules: 'required'},
						{label: i18n.t("account.offers.transfers.flight_departure_time"), placeholder: '00:00', name: 'time', rules: 'required|length:5', mask: '##:##'}
					]}
				]
			},
			source() {
				return JSON.parse(this.$route.query.source);
			}
		},
		methods: {
			transferDetails() {
				this.loading_btn = true;

				transferDetails({storageId: this.$route.params.storageId, code: this.details.id, details: this.form}).then(() => {
					this.addOrder();
				}).catch(err => {
					let data = err.response.data;
					this.$store.commit('notification/errorMessage', {title: data.message})
				}).finally(() => this.loading_btn = false)
			},
			addOrder() {
				this.loading_btn = true;
				let replaceOfferId = this.source.replaceOfferId ? { replaceOfferId: this.source.replaceOfferId } : false;

				addOrder({
					type: "transfer",
					orderId: this.$route.params.orderId,
					offerId: this.details.id,
					storageId: this.$route.params.storageId,
					...replaceOfferId
				}).then(() => {
					this.$router.push({
						name: "itinerary",
						params: {orderId: this.$route.params.orderId, lang: this.$route.params.lang}
					})
				}).catch(err => {
					let data = err.response.data;
					this.$store.commit('notification/errorMessage', {title: data.message})
				}).finally(() => this.loading_btn = false)
			},
			requestOrderHotel() {
				this.loading_btn = true;
				let replaceOfferId = this.form.replaceOfferId ? { replaceOfferId: this.form.replaceOfferId } : false;

				addOrder({
					details: {
						hotelCode: this.$route.params.code,
						userComment: this.userComment,
						selectedTransfer: {
							id: this.active_transfer.id,
							details: this.form
						}
					},
					type: "hotel",
					orderId: this.$route.query.orderId,
					offerId: this.offer.identifier,
					storageId: this.$route.query.storageId,
					...replaceOfferId
				}).then(res => {
					this.$router.push({
						name: "itinerary",
						params: {orderId: res.data.id, lang: this.$route.params.lang},
					})
				}).catch(err => {
					let data = err.response.data;
					this.$store.commit('notification/errorMessage', {title: data.message})
				}).finally(() => this.loading_btn = false)
			},
			closeDialog() {
				this.$emit('close-dialog');
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-transfers-dialog {
		// @include maxw( $grid-breakpoints-xs - 1 ) {
		// 	.app-popup__inner {
		// 		padding: 20px 10px;
		// 	}
		// }
		.a-card-details {
			&-header {
				@include maxw( $grid-breakpoints-xs - 1 ) {
					height: 92px;
					padding-top: 20px;
					padding-bottom: 20px;
					.row {
						flex-direction: column-reverse;
						align-items: normal;
					}
					.col {
						display: grid;
						justify-content: center;
					}
					.col-auto {
						margin-left: auto;
					}
				}
				&-title {
					@include maxw( $grid-breakpoints-xs - 1 ) {
						font-size: 20px;
						font-weight: 400;
					}
				}
			}
		}
		&-content {
			padding: 15px;
			@include minw( $grid-breakpoints-xs ) {
				padding: 20px * .8 35px * .8 25px * .8;
			}
			@include minw( $grid-breakpoints-md ) {
				padding: 20px 35px 25px;
			}
		}
		&-list {
			margin-bottom: 30px;
			&-item {
				font-size: 13px;
				font-weight: 300;
				line-height: 23px;
				display: flex;
				align-items: flex-start;
				@include minw( $grid-breakpoints-xs ) {
					font-size: 14px;
				}
				&:before {
					content: '';
					width: 7px;
					height: 7px;
					background-color: $green-persian;
					border-radius: 50%;
					margin-right: 10px;
					margin-top: 8px;
					flex-shrink: 0;
				}
				&:not(:last-child) {
					margin-bottom: 15px;
				}
			}
		}
		&-form {
			max-width: 620px;
			&-item {
				&-title {
					font-size: 16px;
					font-weight: 400;
					margin-bottom: 15px;
					@include minw( $grid-breakpoints-xs ) {
						font-size: 18px;
					}
				}
			}
		}
		.app-popup {
			z-index: 20;
			&__content {
				padding: 0;
			}
		}
		.backdrop {
			z-index: 16;
		}
	}
</style>
