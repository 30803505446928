var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"a-transfers-dialog",attrs:{"value":_vm.value,"showCloseCross":false,"width":884,"class-content":"border-radius"},on:{"close-dialog":_vm.closeDialog}},[_c('div',{staticClass:"a-card-details-header"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col"},[_c('p',{staticClass:"a-card-details-header-title"},[_vm._v(_vm._s(_vm.$t("account.offers.transfers.transfer_order")))])]),_c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"a-card-details-header-button",on:{"click":function($event){$event.stopPropagation();return _vm.closeDialog.apply(null, arguments)}}},[_c('v-icon',{attrs:{"icon":"cross","size":"17"}})],1)])])]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"a-transfers-dialog-content"},[_c('ul',{staticClass:"a-transfers-dialog-list"},[_c('li',{staticClass:"a-transfers-dialog-list-item"},[_vm._v(_vm._s(_vm.$t("account.offers.transfers.descr_1")))]),_c('li',{staticClass:"a-transfers-dialog-list-item"},[_vm._v(_vm._s(_vm.$t("account.offers.transfers.descr_2")))])]),_c('form',{staticClass:"a-transfers-dialog-form"},[_vm._l((_vm.items),function(item,i){return _c('div',{key:i,staticClass:"a-transfers-dialog-form-item"},[_c('p',{staticClass:"a-transfers-dialog-form-item-title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"row"},_vm._l((item.form),function(element,index){return _c('div',{key:index,staticClass:"col-6"},[_c('ValidationProvider',{attrs:{"name":element.label,"rules":element.rules ? element.rules : { required: false }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('v-text-field',{class:classes,attrs:{"label":element.label,"placeholder":element.placeholder,"name":item.key + "-" + element.name,"type":"text","errorMessage":errors[0] && element.name === "time" ? _vm.$t("account.form.message.errors.time_must_be_format") : errors[0],"mask":element.mask ? element.mask : false},model:{value:(_vm.form[item.key][element.name]),callback:function ($$v) {_vm.$set(_vm.form[item.key], element.name, $$v)},expression:"form[item.key][element.name]"}})]}}],null,true)})],1)}),0)])}),_c('p',{staticClass:"a-transfers-dialog-form-item-title"},[_vm._v(_vm._s(_vm.$t("account.offers.transfers.contact")))]),_c('div',{staticClass:"row"},_vm._l((_vm.contactFields),function(element,index){return _c('div',{key:index,staticClass:"col-6"},[_c('ValidationProvider',{attrs:{"name":element.label,"rules":element.rules ? element.rules : { required: false }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('v-text-field',{class:classes,attrs:{"label":element.label,"placeholder":element.placeholder,"name":element.name,"type":"text","errorMessage":errors[0],"mask":element.mask ? element.mask : false},model:{value:(_vm.form[element.name]),callback:function ($$v) {_vm.$set(_vm.form, element.name, $$v)},expression:"form[element.name]"}})]}}],null,true)})],1)}),0)],2)]),_c('div',{staticClass:"a-card-details-footer"},[_c('div',{staticClass:"a-card-details-footer__divider"},[_c('div',{staticClass:"divider"})]),_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col"},[(_vm.total)?_c('p',{staticClass:"a-card-details-footer-title"},[_vm._v(_vm._s(_vm.total))]):_vm._e(),(_vm.nights)?_c('p',{staticClass:"a-card-details-footer-descr"},[_vm._v(_vm._s(_vm.$t('account.the_price_is_generally_for'))+" "+_vm._s(_vm.nights)+" "+_vm._s(_vm.$helpers.declOfNum(_vm.nights, _vm.$t('account.declOfNum.nights'))))]):_vm._e()]),_c('div',{staticClass:"col-auto"},[_c('v-button',{staticClass:"a-card-details-footer-button",attrs:{"xxsmall":"","color":"green white--text","loading":_vm.loading_btn,"disabled":invalid},on:{"click":function($event){_vm.active_transfer && _vm.active_transfer.id ? _vm.requestOrderHotel() : _vm.transferDetails()}}},[_vm._v(_vm._s(_vm.$t("account.buttons.continue")))])],1)])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }